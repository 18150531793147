<script>
  import { useTranslations } from "@i18n/utils.ts";

  export let lang = "en";
  
  const __e = useTranslations(lang);

  const currentYear = new Date().getFullYear();

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    // document.location = document.location.href;
    location.reload();
  }

  function changeLang(lang) {
    setCookie('mgLanguage', lang, 90);
  }
</script>

<footer class="footer" data-lang="{lang}">
  <div class="footer-nav">
    <div class="social-network">
      <img
        src="/assets/logo.svg"
        class="footer-logo"
        alt="Logo"
        width="152"
        height="130"
      />

      <!-- <ul class="social-menu">
        <li>
          <a href="/#"
            ><img src="/assets/social/facebook.svg" alt="Facebook" /></a
          >
        </li>

        <li>
          <a href="/#"
            ><img src="/assets/social/instagram.svg" alt="Instagram" /></a
          >
        </li>

        <li>
          <a href="/#"><img src="/assets/social/twitter.svg" alt="Twitter" /></a
          >
        </li>
      </ul> -->
    </div>

    <nav class="footer-links">
      <ul>
        <li><a href="/about">{__e("nav.about-us")}</a></li>
        <li><a href="/about/team">{__e("nav.about.team")}</a></li>
        <li><a href="/about/our-customers">{__e("nav.about.customers")}</a></li>
        <li><a href="/pqrsf">{__e("site.pqrsf")}</a></li>
        <li><a href="/habeas-data">{__e("site.habeasData")}</a></li>
         <!-- <li><a href="https://anau.haptica.co" targuet="_blank">ANAÚ</a></li>
        <li><a href="/news">{__e("nav.news")}</a></li>
        <li><a href="/blog">{__e("nav.blog")}</a></li> -->
      </ul>

      <!-- <ul>
        <li><a href="/products">{__e("nav.products")}</a></li>
        <li><a href="/products#nivo">Nivo</a></li>
        <li><a href="/products#geo">Geo</a></li>
      </ul> -->

      <!-- 
      <ul>
        <li><a href="/services">{__e("nav.services")}</a></li>
        <li><a href="/services">Estrategia tecnológica</a></li>
        <li><a href="/services">Analytics de datos</a></li>
        <li><a href="/services">Nube computacional</a></li>
        <li><a href="/services">Moviles</a></li>
        <li><a href="/services">Web</a></li>
        <li><a href="/services">Diseño de producto</a></li>
      </ul> 
    -->
    </nav>

    <div class="footer-contact-info">
      {#if lang=='es-CO'}
        <p>
          <strong>Estamos aquí</strong> para ayudarte y responder tus dudas
        </p>
        {:else}
        <p>
          <strong>We are here</strong> to help you and answer your questions
        </p>
      {/if}
      <a
        href={"/contact"}
        class="btn btn-primary btn-lg btn-contactar">{__e("nav.contact")}</a
      >

      <ul class="language-selector">
        <li>
          <a href="/" on:click|preventDefault={()=>changeLang('es-CO')} class={lang=='es-CO' ? 'current-lang' : ''} title="Español"><img src="/assets/es.png" alt="Espalol" /></a>
        </li>
        <li>
          <a href="/" on:click|preventDefault={()=>changeLang('en')} class={lang=='en' ? 'current-lang' : ''} title="English"><img src="/assets/en.png" alt="English" /></a>
        </li>
      </ul>
    </div>
  </div>
  <div class="footer-copy">
    <span>© {currentYear} Meridian . All rights reserved.</span>
  </div>
</footer>

<style>

  .btn-contactar {
    color: #000;
  }
  .language-selector {
    padding: 0;
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100px;
    gap: 15px;
  }

  .language-selector li {
    display: block;
  }
  .language-selector li a img {
    width: 40px;
    height: 40px;
    opacity: 0.8;
    transition: all 0.3s ease;
    filter: grayscale(1);
  }

  .language-selector li a:hover img, 
  .language-selector li a.current-lang img{
    opacity: 1;
    filter: grayscale(0);
  }

  .footer-nav {
    background-color: #1b1b1b;
    padding: 80px 137px 50px 137px;
    display: grid;
    grid-template-columns: minmax(300px, 1fr) 50% auto;
    grid-template-areas: "social links contact";
    grid-template-rows: 1fr; 
  }

  .social-network {
    padding-right: 100px;
    grid-area: social;
  }

  .social-menu {
    width: 150px;
    margin-top: 20px;
    padding: 0px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(36px, 1fr));
  }
  .social-menu li {
    display: flex;
    justify-content: center;
  }

  .social-menu li a img {
    opacity: 0.7;
    transition: all 0.3s ease;
  }

  .social-menu li a:hover img {
    opacity: 1;
  }

  .footer-links {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    border-right: solid 1px #707070;
    grid-area: links;
  }

  .footer-links ul {
    padding: 0px;
    margin: 0px 0px 20px 0px;
  }

  .footer-links ul li a {
    color: #e4e4e4;
    font-size: 16px;
    font-weight: 300;
    line-height: 35px;
    transition: all 0.2s ease;
  }

  .footer-links ul li a:hover {
    color: white;
    text-decoration: underline;
  }
  .footer-contact-info {
    padding-left: 95.5px;
    grid-area: contact;
  }
  .footer-contact-info p {
    color: white;
    font-size: 16px;
    font-weight: 200;
    line-height: 24px;
    margin-bottom: 13px;
  }

  .footer-copy {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    text-align: center;
    color: #c2c2c2;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    height: 61px;
  }

  @media (max-width: 1440px) {
    .footer-nav {
      padding: 80px 100px 50px 100px;
      grid-template-areas: "social links links"
                           "contact  contact contact";
      grid-template-columns: auto;
    }

    .footer-links {
      border-right: none;
    }

    .footer-contact-info {
      padding-left: 0px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 924px) {
    .footer-nav {
      padding: 50px 100px 50px 100px;
      grid-template-areas: "social social social"
                           "links links links"
                           "contact  contact contact";
      grid-template-columns: auto;
    }

    .social-network {
      padding-right: 0;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .footer-links {
      margin-top: 60px;
      text-align: center;
    }


  }

  @media (max-width: 768px) {
    .social-network {
      margin-bottom: 40px;
      padding-right: 0;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .footer-nav {
      padding: 50px 25px 50px 25px;
      grid-template-columns: auto;
    }

    .footer-links {
      grid-template-columns: auto;
      border-right: none;
      display: none;
    }

    .footer-links ul {
      margin-bottom: 30px;
    }

    .footer-contact-info {
      padding-left: 0px;
      margin-top: 0px;
    }
  }

</style>
